<template>
  <v-dialog v-model="show" width="600">
    <v-card>
      <v-card-title>
        Копировать маршрут № {{ selected.vcroutesRoutecode }}
      </v-card-title>
      <v-divider />

      <v-card-text class="pt-4 body-1">
        <p>
          После копирования маршрута необходимо 
          найти созданную копию маршрута 
          и внести в нее изменения 
          через форму редактирования.
        </p>
        <p>
          Подтвердите, что хотите создать копию маршрута.
        </p>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-btn
          @click="show = false"
          color="red"
          outlined
        >
          Отмена
        </v-btn>
        <v-spacer />

        <v-btn
          @click="copyRoute"
          :loading="loading"
          color="primary"
          dark
        >
          Копировать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'CopyRoute',
    props: {
      collection: {
        type: Object,
        required: true,
      }
    },
    data: () => ({
      show: true,
      loading: false,
      selected: null,
    }),
    created() {
      this.selected = this.collection.master.selected;

      if (!this.selected) {
        jet.msg({
          text:'Необходимо выбрать запись!',
          color:'warning'
        });
        return;
      }
    },
    methods: {
      async copyRoute() {
        this.loading = true;
        const id = jet.utils.uuidv4();
        const currentDate = new Date();
        let resp = '';
        let {id: userId, tenant: tenantId} = this.$store.state.profile;

        const createQuery = {
          type: 'core-create', 
          query: 'sin2:/v:acd2f542-598c-4540-b46c-03375098d467',
          params: [
            {id: 'id', type:'id', value: id},
            {id: 'routeCode', type:'string', value: this.selected.vcroutesRoutecode},
            {id: 'routeName', type: 'string', value: this.selected.vcroutesRoutename},
            {id: 'startDt', type: 'dateTime', value: new Date(currentDate.toDateString())},
            {id: 'routeTypeID', type: 'id', value: this.selected.vcroutesRoutetypeid},
            {id: 'streets', type: 'string', value: this.selected.vcroutesStreets},
            {id: 'trafficKindID', type: 'id', value: this.selected.vcroutesTraffickindid},
            {id: 'landingOrderID', type: 'id', value: this.selected.vcroutesLandingorderid},
            {id: 'vehicleTypeID', type:'id', value: this.selected.vcroutesVehicletypeid},
            {id: 'routeSpec', type: 'string', value: this.selected.vcroutesRoutespec},
            {id: 'trackSpec', type: 'string', value: this.selected.vcroutesTrackspec},
            {id: 'length', type: 'double precision', value: this.selected.vcroutesLength},
            {id: 'displayOnWeb', type: 'boolean', value: this.selected.vcroutesDisplayonweb},
            {id: 'verStatus', type: 'id', value:'ed4762ee-ca91-4b52-89e8-1176fb0a296e'},
            {id: 'version', type: 'integer', value: 1}
          ]
        };

        try {
          resp = await jet.http.post(createQuery);
          if (resp.error){
            throw resp.error;
          }
        } catch(e) {
          this.loading = false;
          this.show = false;
          jet.msg({text:'Не удалось скопировать маршрут', type:'warning'});
          console.error('CopyRoute:', e);
        }

        const copyQuery = {
          type: 'query', 
          query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.copyRouteWeb',
          params: {
            in_routeID: this.selected.vcroutesId,
            in_newrouteID: id,
            in_date: currentDate,
            in_tenantID: tenantId,  //(this.selected._sec_attrsTenantid),
            in_userID: userId,      //(this.selected._sec_attrsUserid),
            in_type: 2
          }
        }

        try {
          resp = await jet.http.post(copyQuery);
          if (resp.error){
            throw resp.error;
          }
          jet.msg({
            text: `Копия маршрута 
              № ${this.selected.vcroutesRoutecode} 
              ${this.selected.vcroutesRoutename} успешно создана!`
          });
        } catch(e) {
          jet.msg({
            text:'Не удалось скопировать маршрут.', 
            color:'warning'
          });
          console.error('CopyRoute: copyQuery', e);
          return;
        }
        this.loading = false;
        this.show = false;
        this.collection.refresh(this.selected.vcroutesId);
      }
    }
  }
</script>